body{
  /*background: #51b8ab0a;*/
  background: #fff;
  font-size: 14px;
}
a:hover{
  text-decoration: none;
}
hr{
  border-top: 1px solid rgb(0 0 0 / 4%);
}
.text-active{
  color: #4485ef !important;
}
.text-active:hover{
  color:#4485ef;
}
.hide{
  display: none;
}
.show{
  display: block;
}
/*Login*/
.login-wrap{
  margin: 100px auto;
}
.login-wrap .login-container{
  display: flex;
  box-shadow: 0px 5px 15px #d0d0d0;
}
.login-container .left-img{
  width: 50%;
  background:#4485ef;
  background-image: url('assets/images/log_in_section.png');
  background-position: inherit;
  background-size: cover;
  display: flex;
}
/*.login-container .left-img:before{
  content: '';
  position: absolute;
  left: 15px;
  width: 48%;
  height: 100%;
  background: #000000;
  opacity: .5;
  overflow: hidden;
}*/
 /*.banner:before{
  content: '';
  position: absolute;
  left:0;
  width: 100%;
  height: 170px;
  background: #000000;
  opacity: .5;
  overflow: hidden;
 }*/
.login-container .left-img .heading-content{
    vertical-align: middle;
    margin: auto;
    color: #fff;
    text-align: center;
    z-index: 99;
}
.login-container .left-img .heading-content h2{
  font-size: 45px;

}
.login-container .right-form{
  padding: 60px 30px;
  width: 50%;
  background: #fff;
}
.login-container .right-form .form-group{
  position: relative;
}
.right-form .form-group .form-control{
  padding-left: 0;
  font-size: 14px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding-left: 5px;
}
.login-icon{
  position: absolute;
    right: 15px;
    top: 10px;
    color:#4485ef;
    font-size: 12px;
}
.forgot-link{
  display: block;
    text-align: center;
    color: #737373;
    font-size: 14px;
        margin-top: 20px;
}
.forgot-link:hover{color: #4485ef;;}
.login-container .loginBtn{
  width: 75%;
  border: 0;
  background:#4485ef;
  padding: 10px;
  color: #fff;
  border-radius: 50px;
  display: block;
    text-align: center;
    margin: auto;
    text-decoration: none;
}
.login-container .loginBtn:hover{
  box-shadow: 0px 8px 20px -5px #4485ef;
  transition: all 0.3s ease 0s;
}
/*Login*/

/*Form*/
.form-control{
  border-radius: 0;
  font-size: 14px;
}
.form-control:focus, .form-control:active{
  box-shadow: none;
  border: 1px solid#4485ef;
}
.form-control:disabled, .form-control[readonly]{
  cursor: not-allowed;
}
.err-msg{
  font-size: 14px;
  color: red;
}
/*Form*/
/*Header*/
header{
  background: #fff;
  box-shadow: 0 5px 10px #0000000a;
}
header h3{
  color: #313131;
  font-weight: 500;
}
.navbar-expand-lg{
  padding: 15px 1rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
  color: #313131;
  font-weight: 500;
  padding: .5rem 1rem;
  margin: 0 5px;
  border: 1px solid transparent;
}
.active{
 color: #4485ef;transition: all 0.3s ease 0s;border: 1px solid;
}
.navbar-expand-lg .navbar-nav .nav-item:hover .nav-link{
  color:#4485ef;
  transition: all 0.3s ease 0s;
  border: 1px solid;
}

.navbar-nav .nav-item.dropdown .dropdown-menu.show .nav-link.d-flex{
  border: 1px solid transparent !important;  
  margin: 0;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
  border-radius: 4px;
    border: 0;
    box-shadow: 0 2px 20px -6px #4485ef;
}
.navbar-expand-lg .navbar-nav .dropdown-menu.show .nav-link:hover{
  background:  #b3d0ff;
  color: #4485ef;
  transition: all 0.3s ease 0s;
}
.navbar-expand-lg .navbar-nav .dropdown-menu.show .nav-link:focus{

}
.err-msg{
  text-align: left;
  color: red;
  display: block;
  font-size: 14px;
  margin: 5px;
  padding-left: 10px;
  
}
.nav-link div{
  font-size: 16px !important;
}
/*Header*/

/*Bootstrap*/
.card{
  border-radius: 0;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 30px;
}
.modal-footer{
  border-top: 0;
}
/*Bootstrap*/

/*Common*/
section{
  margin: 50px 0;
  min-height: 350px;
}
.main-container{
  min-height: 300px;
}
.banner{
  background-image: url('assets/images/banner.jpg');
  background-position: bottom;
  background-size: cover;
  height: 110px;
  display: flex;
}

.banner h2{
  vertical-align: middle;
    margin: auto;
    color: #4485ef;
    text-shadow: 1px 1px 0px #416b05;
}
.search-container{
  display: flex;
    border-radius: 30px;
    border: 1px solid #ededed;
    background: #fff;
    width: 540px;
    margin: auto ;
    vertical-align: middle;
    box-shadow: 0 5px 15px -5px #a7a7a7;
}
.hover_category {
    position: relative;
    border-radius: 25px 0 0 25px;
}
.select_option {
    display: flex;
    align-items: center;
}
.select_option:focus, .search_box input:focus{
  outline: 0;
  border: none;
}
.status_allorders {
  width: 100%;
  display: block;
}
.help-text{
  color:#857f7f;
  font-size: 12px;
  font-style: italic;
  display: block;
  text-align: center;
  position: absolute;
  top: 10px;
  left: 5px;
}
.hover_category .select_option {
    border: 0;
    background: #f5f5f5;
    padding-left: 25px;
    padding-right: 35px;
    font-size: 13px;
    height: 45px;
    line-height: 45px;
    border-radius: 25px 0 0 25px;
}
.search_box {
    position: relative;
    width: 100%;
}
.search_box label{
  margin-bottom: 0;
  width: 100%
}
.search_box input {
  border: 0;
  background: inherit;
  width: 100%;
  height: 45px;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  padding: 0 25px 0 20px;
  opacity: 0.7;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.search_box button {
    border: 0;
    position: absolute;
    top: 0;
    height: 100%;
    line-height: 48px;
    width: 60px;
    padding: 0;
    text-align: center;
    right: 0;
    font-weight: 400;
    font-size: 20px;
    border-radius: 0 30px 30px 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: #4485ef;
    color: #fff;
}
.order-table{
  border: 1px solid #ededed;
  
}
.react-bootstrap-table table{
  table-layout: auto;
}
.table-bordered .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 10px 0 10px 0px!important;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  float: right;
}
.table-bordered .dropup .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.order-table thead tr th,
.table-bordered thead th {
    border-bottom: 3px solid #4485ef !important;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 10px;

}
.table-bordered.all-items-table thead th{
  position:sticky;
  top:0px;
}
/* .table-bordered.all-items-table {
  max-height: 250px;
  overflow: auto;
} */
 .table-bordered.all-items-table tbody td{
  vertical-align: top;
  position: relative;
}
/* .all-items-table tbody {
display:block;
height:500px;
overflow-y:scroll;
}
.all-items-table  thead{
display:table;
width:98.7%;
table-layout:fixed;
}
.all-items-table  tbody tr {
display:table;
width:100%;
table-layout:fixed;
} */
.Confirmation-modal .modal-footer {
  justify-content: center;
  position: relative;
  padding-top: 0;
}
.Confirmation-modal .modal-footer button {
  min-width: 110px;
}
.Confirmation-modal label {
  font-size: 20px;
  text-align: center;
  display: block;
  margin-bottom: 0;
}
.table-bordered thead th, .table-bordered tbody td{
  border: none;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
}
.table-bordered thead th:focus{
  border-radius: 0;
  border: none;
  border-bottom: 3px solid #4485ef;
  outline: none;
}
.order-table tbody tr td {
    padding: 10px;
    font-weight: 600;
    vertical-align: middle;
}
.table-bordered tbody tr td{
  padding-right: 2rem;
}
 
table tbody tr td .form-control{
  padding: 5px;
}
.button-success,.button-success:hover,.button-success:active{
  background:#4485ef;
  border-color:#4485ef;
}
.submit-btn{
  border: 0;
  background: #4485ef;
  padding: 10px;
  color: #fff;
  border-radius: 0;
}
.submit-btn-outline{
  border: 1px solid #4485ef;
  padding: 10px;
  color: #4485ef;
  font-weight: 500;
  background: transparent;
}
.submit-btn:hover, .submit-btn-outline:hover{
  background: #4485ef;
  color: #fff;
  transition: all 0.3s ease 0s;
}
.footer-container{
  padding: 20px 10px;
  box-shadow: 0 0 10px #eaeaea;
  background: #fff;
}
.footer-container div{
  font-size: 14px;
}
.btn.btn-primary[disabled] {
  background-color: #696969;
}
.breadcrumb-route a{
  color: #212529;
    font-weight: 500;
}
.breadcrumb-route a:hover{
  color:#4485ef;
}
.heading-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.custom-loader {
  text-align: center;
}
td.input-table{
  word-wrap: break-all;
}
td.input-table span {
  border: 1px solid #ced4da;
  padding: 10px;
  min-width: 50px;
}
td.input-table.disable span{
  background-color: #ddd;
}
.alert {
  letter-spacing: 0;
  font-size: 13px;
  border: none;
  padding: 10px 10px;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
button.close.close_forgot_pass {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 16px;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.login-wrap .alert label {
  margin: 0;
}
.header-logo img{
  width: 200px;
}
.header-logo{
  margin-right: 40px;
    display: inline-block;
}.align-items-center{
  display: flex;
  justify-content: center;
}
.align-items-center h2{
  vertical-align: middle;
    margin: auto;
    color: #4485ef;
    text-shadow: 1px 1px 0px #6f83f5;
}
.text-onimage{
  color: white;
  background: #1f1f1f;
  padding: 5px;
}
td.input-table div {
  position: relative;
}
@media (max-width: 993px){
  
  
}
@media (max-width: 767px){
  .search-container{
    margin: auto 30px; 
  }
  .login-wrap  .login-container, .heading-filter{
    display: block;
  }
  .login-container .left-img{
    width: 100%;
    height: 150px;
  }/*
  .login-container .left-img:before{
    width: 92.5%;
    height: 150px;
  }*/
  .login-container .right-form{
    width: 100%;
  }
  .banner h2{
    font-size: 1.3rem;
  }
  .navbar-expand-lg{
    padding: 0;
  }

}